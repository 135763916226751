import "./normilise.css";
import "./style.scss";

import * as THREE from "three";
import { REVISION } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module.js";
import { LoadingManager } from "three/src/loaders/LoadingManager";
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment.js";
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import CSSRulePlugin from "gsap/CSSRulePlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, SplitText, CSSRulePlugin);

gsap.config({
  // force3D: false,
  nullTargetWarn: false,
});
ScrollTrigger.normalizeScroll(true);
ScrollTrigger.config({ ignoreMobileResize: true });

document.addEventListener("DOMContentLoaded", init, false);

let start_tl;

function init() {
  setTimeout(() => {
    ScrollTrigger.sort();
    ScrollTrigger.refresh();
  }, 500);
  /////////////////////////////////////all/////////////////////

  //top menu links
  document.querySelector(".btn_about").addEventListener("click", () => {
    if (window.location.pathname !== "/") {
      window.location.replace("/");
    }
    gsap.to(window, { duration: 0.1, scrollTo: ".content .sub_title" });
  });
  document.querySelector(".btn_portfolio").addEventListener("click", () => {
    if (window.location.pathname !== "/") {
      window.location.replace("/");
    }
    gsap.to(window, { duration: 0.1, scrollTo: ".slider" });
  });
  document.querySelector(".btn_mission").addEventListener("click", () => {
    if (window.location.pathname !== "/") {
      window.location.replace("/");
    }
    gsap.to(window, { duration: 0.1, scrollTo: ".mission" });
  });
  document.querySelector(".btn_faq").addEventListener("click", () => {
    if (window.location.pathname !== "/") {
      window.location.replace("/");
    }
    gsap.to(window, { duration: 0.1, scrollTo: ".faq" });
  });

  /*    document.querySelector('.btn_pricing').addEventListener("click",()=>{
            if (window.location.pathname !== "/"){
                window.location.replace('/');
            }
            gsap.to(window, {duration: 0.1, scrollTo: ".price_section"});
        })*/

  //download_btn
  const dwn_tl = gsap.timeline({ paused: true });
  dwn_tl.to(".download_modal", { autoAlpha: 1, duration: 0.001 });
  dwn_tl.to(".download_modal .overlay", { opacity: 0.9, duration: 1 });
  dwn_tl.from(
    ".download_modal .wrapper",
    { y: 100, opacity: 0, duration: 1 },
    "<"
  );

  const download_btn = document.querySelectorAll(".download_btn");
  download_btn.forEach((el) => {
    el.addEventListener("click", () => {
      document.querySelector(".download_btn").classList.add("active");
      dwn_tl.play();
    });
    document
      .querySelector(".download_modal .btn_close")
      .addEventListener("click", () => {
        if (el.classList.contains("active")) {
          dwn_tl.reverse();
        }
        el.classList.remove("active");
      });
    document
      .querySelector(".download_modal .overlay")
      .addEventListener("click", () => {
        if (el.classList.contains("active")) {
          dwn_tl.reverse();
        }
        el.classList.remove("active");
      });
  });

  //bottom menu links
  document.querySelector(".btn_about_bot").addEventListener("click", () => {
    if (window.location.pathname !== "/") {
      window.location.replace("/");
    }
    gsap.to(window, { duration: 0.1, scrollTo: ".content .sub_title" });
  });
  document.querySelector(".btn_portfolio_bot").addEventListener("click", () => {
    if (window.location.pathname !== "/") {
      window.location.replace("/");
    }
    gsap.to(window, { duration: 0.05, scrollTo: ".about" });
    gsap.to(window, { duration: 0.05, delay: 0.05, scrollTo: ".slider" });
  });

  document.querySelector(".btn_mission_bot").addEventListener("click", () => {
    if (window.location.pathname !== "/") {
      window.location.replace("/");
    }
    gsap.to(window, { duration: 0.1, scrollTo: ".mission" });
  });
  /*document.querySelector('.btn_pricing_bot').addEventListener("click",()=>{
        if (window.location.pathname !== "/"){
            window.location.replace('/');
        }
        gsap.to(window, {duration: 0.1, scrollTo: ".price_section"});
    })*/
  document.querySelector(".btn_faq_bot").addEventListener("click", () => {
    if (window.location.pathname !== "/") {
      window.location.replace("/");
    }
    gsap.to(window, { duration: 0.1, scrollTo: ".faq" });
  });

  //btn_social
  const btn_social = document.querySelector(".btn_social");
  const modal_a = document.querySelectorAll(".modal a");
  modal_a.forEach((el) => {
    el.addEventListener("click", () => {
      gsap.to(".modal", {
        autoAlpha: 0,
        top: () => (window.innerWidth > 1024 ? "200px" : "100px"),
      });
      btn_social.classList.remove("active");
    });
  });

  btn_social.addEventListener("click", () => {
    if (btn_social.classList.contains("active")) {
      gsap.to(".modal", {
        autoAlpha: 0,
        top: () => (window.innerWidth > 1024 ? "100px" : "0px"),
      });
      btn_social.classList.remove("active");
    } else {
      gsap.to(".modal", {
        autoAlpha: 1,
        top: () => (window.innerWidth > 1024 ? "100px" : "0px"),
      });
      btn_social.classList.add("active");
    }
  });
  //menu_mobile

  document.querySelector(".btn_menu").addEventListener("click", () => {
    document.querySelector(".mobile_menu").classList.add("active");
    gsap.to(".menu_overlay", { autoAlpha: 1, left: 0 });
    gsap.to(".logo", { autoAlpha: 0 });
    gsap.from(".mobile_menu .moveA", { opacity: 0, stagger: 0.15, delay: 0.5 });
  });
  document.querySelector(".btn_close").addEventListener("click", () => {
    gsap.to(".menu_overlay", { autoAlpha: 0, left: "100vw" });
    gsap.to(".logo", { autoAlpha: 1 });
  });
  //top_menu_mobile
  document
    .querySelector(".mobile_menu .btn_about")
    .addEventListener("click", () => {
      if (window.location.pathname !== "/") {
        window.location.replace("/");
      }
      if (document.querySelector(".mobile_menu").classList.contains("active")) {
        document.querySelector(".mobile_menu").classList.remove("active");
        gsap.to(".menu_overlay", { left: "100vw", duration: 1, delay: 0.2 });
        gsap.to(".menu_overlay", { autoAlpha: 0, duration: 1, delay: 1.2 });
        gsap.to(".logo", { autoAlpha: 1, duration: 1 });
        gsap.to(window, { duration: 0.1, scrollTo: ".content .sub_title" });
      }
    });
  document
    .querySelector(".mobile_menu .btn_mission")
    .addEventListener("click", () => {
      if (window.location.pathname !== "/") {
        window.location.replace("/");
      }
      if (document.querySelector(".mobile_menu").classList.contains("active")) {
        document.querySelector(".mobile_menu").classList.remove("active");
        gsap.to(".menu_overlay", { left: "100vw", duration: 1, delay: 0.2 });
        gsap.to(".menu_overlay", { autoAlpha: 0, duration: 1, delay: 1.2 });
        gsap.to(".logo", { autoAlpha: 1, duration: 1 });
        gsap.to(window, { duration: 0.1, scrollTo: ".mission" });
      }
    });
  document
    .querySelector(".mobile_menu .btn_portfolio")
    .addEventListener("click", () => {
      if (window.location.pathname !== "/") {
        window.location.replace("/");
      }
      if (document.querySelector(".mobile_menu").classList.contains("active")) {
        document.querySelector(".mobile_menu").classList.remove("active");
        gsap.to(".menu_overlay", { left: "100vw", duration: 1, delay: 0.2 });
        gsap.to(".menu_overlay", { autoAlpha: 0, duration: 1, delay: 1.2 });
        gsap.to(".logo", { autoAlpha: 1, duration: 1 });
        gsap.to(window, { duration: 0.1, scrollTo: ".slider" });
      }
    });
  document
    .querySelector(".mobile_menu .btn_faq")
    .addEventListener("click", () => {
      if (window.location.pathname !== "/") {
        window.location.replace("/");
      }
      if (document.querySelector(".mobile_menu").classList.contains("active")) {
        document.querySelector(".mobile_menu").classList.remove("active");
        gsap.to(".menu_overlay", { left: "100vw", duration: 1, delay: 0.2 });
        gsap.to(".menu_overlay", { autoAlpha: 0, duration: 1, delay: 1.2 });
        gsap.to(".logo", { autoAlpha: 1, duration: 1 });
        gsap.to(window, { duration: 0.1, scrollTo: ".faq" });
      }
    });
  /*document.querySelector('.mobile_menu .btn_pricing').addEventListener("click",()=>{
        if (window.location.pathname !== "/"){
            window.location.replace('/');
        }
        if (  document.querySelector('.mobile_menu').classList.contains("active")) {
            document.querySelector('.mobile_menu').classList.remove("active")
            gsap.to(".menu_overlay",{ left:'100vw', duration:1, delay:0.2})
            gsap.to(".menu_overlay",{autoAlpha:0, duration:1, delay:1.2})
            gsap.to(".logo",{autoAlpha:1, duration:1})
            gsap.to(window, {duration: 0.1, scrollTo: ".price_section"});
        }

    })*/
  document
    .querySelector(".mobile_menu .download_btn")
    .addEventListener("click", () => {
      if (document.querySelector(".mobile_menu").classList.contains("active")) {
        document.querySelector(".mobile_menu").classList.remove("active");
        gsap.to(".menu_overlay", { left: "100vw", duration: 1, delay: 0.2 });
        gsap.to(".menu_overlay", { autoAlpha: 0, duration: 1, delay: 1.2 });
        gsap.to(".logo", { autoAlpha: 1, duration: 1 });
      }
    });

  /////////////////////////////////////// home /////////////
  if (window.location.pathname === "/") {
    //intro
    const speed = 1;
    const menuEl = gsap.utils.toArray(".menu .moveA");
    gsap.from(menuEl, { y: 100, duration: speed, stagger: 0.2, delay: 4 });

    threeJs();
    threeJs_logo();

    //slogan

    let animateWords = document.querySelector(".slogan");

    new SplitText(animateWords, { type: "chars", charsClass: "charsParent" });
    new SplitText(animateWords, { type: "chars", charsClass: "charsChild" });

    start_tl = gsap.timeline({ paused: true });

    start_tl.from(".charsChild", {
      yPercent: 110,
      rotation: 90,
      delay: 3,
      duration: 0.5,
      stagger: 0.02,
      onComplete: () => {
        const lineAfter = CSSRulePlugin.getRule(".slogan span:after");
        gsap.to(lineAfter, {
          cssRule: { width: "100%" },
          duration: 1,
          ease: "power4.inOut",
        });
      },
    });
    start_tl.from(".address1", { opacity: 0, duration: 1.5 }, "3.4");
    start_tl.from(".address2", { opacity: 0, duration: 1.5 }, "3.3");
    start_tl.from(".btn_menu", { opacity: 0, duration: 1.5 }, "3.2");
    //pin slider
    ScrollTrigger.create({
      trigger: ".slider",
      start: "top top",
      end: "+=900%",
      pin: true,
    });
    //about
    let tl_head = gsap.timeline({
      scrollTrigger: {
        trigger: ".container",
        start: "top top",
        toggleActions: "play none none reset",
      },
    });
    tl_head.from(".y_line", {
      width: "0%",
      duration: speed,
      ease: "power3.inOut",
    });
    tl_head.from(
      ".top_cube",
      { yPercent: -100, duration: speed, ease: "power3.inOut" },
      "<+=" + speed / 2
    );

    let h1_lines = document.querySelectorAll(".about_section .text");
    new SplitText(h1_lines, { type: "words", wordsClass: "wordsParent" });
    let l_h1 = new SplitText(h1_lines, {
      type: "words",
      wordsClass: "wordsChild",
    });

    const tl_h1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".content",
        start: "top 30%",
        toggleActions: "restart none none reverse",
      },
    });

    tl_h1.from(l_h1.words, {
      yPercent: 100,
      duration: 0.5,
      stagger: 0.04,
      ease: "back",
    });

    //see more
    const see_m_tl = gsap.timeline({ paused: true });
    const f_m = new SplitText(".seemore_btn_f_move", { type: "chars" });
    const s_m = new SplitText(".seemore_btn_s_move", { type: "chars" });
    see_m_tl.to(s_m.chars, { yPercent: -100, duration: 0.3, stagger: 0.04 });
    see_m_tl.to(
      f_m.chars,
      { yPercent: -100, duration: 0.3, stagger: 0.04 },
      "<+=0.15"
    );
    /*document.querySelector('.see_more a').addEventListener('mouseover',()=>{
            see_m_tl.restart()
        })
        document.querySelector('.see_more a').addEventListener('mouseout',()=>{
            see_m_tl.reverse()
        })
    */

    //sub_title
    const sub_title = gsap.utils.toArray(".sub_title");
    sub_title.forEach((el, index) => {
      if (el.classList.contains("correction")) {
        let tl_sub_bg = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: () => (window.innerWidth > 567 ? "top 120%" : "top 160%"),
            toggleActions: "play none none reverse",
          },
        });
        tl_sub_bg.to(el.querySelector(".sub_bg"), {
          scaleX: 0,
          duration: 1,
          ease: "power4.inOut",
        });
      } else {
        let tl_sub_bg = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        });
        tl_sub_bg.to(el.querySelector(".sub_bg"), {
          scaleX: 0,
          duration: 1,
          ease: "power4.inOut",
        });
      }
    });
    //clients
    const grid_img = gsap.utils.toArray(".grid img");
    let tl_clients = gsap.timeline({
      scrollTrigger: {
        trigger: ".grid",
        start: () => (window.innerWidth > 567 ? "top 120%" : "top 160%"),
        toggleActions: "play none none reverse",
      },
    });
    tl_clients.from(grid_img, {
      yPercent: 30,
      opacity: 0,
      duration: 1,
      stagger: 0.05,
      ease: "power4.inOut",
    });
    //cubes
    let tl_rop_cover = gsap.timeline({
      scrollTrigger: {
        trigger: ".mission",
        start: "top center",
        end: "bottom center",
        scrub: 1,
        toggleActions: "play reverse play reverse",
      },
    });
    tl_rop_cover.to(".mission_section .cover", { top: "60%" });

    let tl_cover_for_full_left = gsap.timeline({
      scrollTrigger: {
        trigger: ".bespoke_service",
        start: "top center",
        end: "bottom top",
        scrub: 1,
        toggleActions: "play reverse play reverse",
      },
    });
    tl_cover_for_full_left.to(".cover_for_full_left", {
      top: "25%",
      left: "1%",
    });

    let tl_cover_for_full_right = gsap.timeline({
      scrollTrigger: {
        trigger: ".note",
        start: "top center",
        end: "bottom top",
        scrub: 1,
        toggleActions: "play reverse play reverse",
      },
    });
    tl_cover_for_full_right.to(".cover_for_full_right", {
      top: "-6%",
      left: "-15%",
    });
    //images
    const human_img = gsap.utils.toArray(".human_img");
    human_img.forEach((el) => {
      if (el.classList.contains("last") && window.innerWidth < 567) {
        let tl_human_img = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: "top 150%",
            toggleActions: "play none none reverse",
          },
        });
        tl_human_img.to(el.querySelector(".f_plane"), { width: "0%" });
        tl_human_img.to(
          el.querySelector(".s_plane"),
          { width: "0%" },
          "<+=0.3"
        );
        tl_human_img.from(
          el.querySelector("img"),
          { width: "150%", height: "100%" },
          "<"
        );
        tl_human_img.from(el.querySelector(".line"), { width: "0%" });
      } else {
        let tl_human_img = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        });
        tl_human_img.to(el.querySelector(".f_plane"), { width: "0%" });
        tl_human_img.to(
          el.querySelector(".s_plane"),
          { width: "0%" },
          "<+=0.3"
        );
        tl_human_img.from(
          el.querySelector("img"),
          { width: "150%", height: "100%" },
          "<"
        );
        tl_human_img.from(el.querySelector(".line"), { width: "0%" });
      }
    });

    //accordeon
    const acc = document.querySelectorAll(".accordion");
    acc.forEach((el) => {
      el.addEventListener("click", function () {
        acc.forEach((el) => {
          el.classList.remove("active");
          const panel = el.nextElementSibling;
          panel.style.maxHeight = null;
        });
        el.classList.add("active");
        const panel = el.nextElementSibling;
        if (!el.classList.contains("active")) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    });
  } //end path /

  ///////////////////////////// portfolio ///////////////////////
  if (window.location.pathname === "/portfolio.html") {
    //intro
    const speed = 1;
    const menuEl = gsap.utils.toArray(".menu .moveA");
    gsap.from(menuEl, { y: 100, duration: speed, stagger: 0.2, delay: 1 });
    gsap.from(".line_port", { width: "0%", duration: speed, delay: 1.5 });

    document.querySelector("body").classList.add("portfolio");
    gsap.to(".preloader", { autoAlpha: 0, duration: 3, delay: 1 });
    gsap.to(".logo", {
      width: () => (window.innerWidth > 1024 ? "2.08vw" : "29px"),
      x: 0,
      y: 0,
      transformOrigin: "0% 0%",
      top: () =>
        window.innerWidth > 1024
          ? "2.08vw"
          : window.innerWidth >= 567 &&
            window.innerWidth < 1024 &&
            window.innerWidth / window.innerHeight < 1
          ? "58px"
          : window.innerWidth < 567 &&
            window.innerWidth / window.innerHeight < 1
          ? "30px"
          : "30px",
      left: () =>
        window.innerWidth > 1024
          ? "6.944vw"
          : window.innerWidth >= 567 &&
            window.innerWidth < 1024 &&
            window.innerWidth / window.innerHeight < 1
          ? "50px"
          : window.innerWidth < 567 &&
            window.innerWidth / window.innerHeight < 1
          ? "30px"
          : "30px",
      yPercent: 0,
      xPercent: 0,
      delay: 1.4,
      duration: 2,
      ease: "power4.inOut",
      onComplete: () => {
        document.querySelector("html").classList.remove("loader");
        niraEmbedInit(document.getElementById("niraEmbedAsset1"), {
          maxActivePerPage: 1,
        });
        niraEmbedInit(document.getElementById("niraEmbedAsset2"), {
          maxActivePerPage: 1,
        });
        niraEmbedInit(document.getElementById("niraEmbedAsset3"), {
          maxActivePerPage: 1,
        });
        niraEmbedInit(document.getElementById("niraEmbedAsset4"), {
          maxActivePerPage: 1,
        });
        niraEmbedInit(document.getElementById("niraEmbedAsset5"), {
          maxActivePerPage: 1,
        });
        niraEmbedInit(document.getElementById("niraEmbedAsset6"), {
          maxActivePerPage: 1,
        });
        document.querySelector("html").classList.remove("loader");
      },
    });
    gsap.to(".logo .l_name", {
      x: 0,
      y: 0,
      transformOrigin: "0% 0%",
      top: () =>
        window.innerWidth > 1024
          ? "0px"
          : window.innerWidth >= 567 &&
            window.innerWidth < 1024 &&
            window.innerWidth / window.innerHeight < 1
          ? "5px"
          : window.innerWidth < 567 &&
            window.innerWidth / window.innerHeight < 1
          ? "0px"
          : "0px",
      left: () => (window.innerWidth > 1024 ? "2.5vw" : "35px"),
      yPercent: 0,
      xPercent: 0,
      delay: 1.4,
      duration: 2,
      ease: "power4.inOut",
    });
    gsap.to(".logo a", { color: "#111", delay: 1.4, duration: 2 });
  } //end path /portfolio
} //end init

function threeJs() {
  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();
  const manager = new LoadingManager();
  const progress_bar = document.querySelector(".progress_bar_load");
  const preloader = document.querySelector(".preloader");

  let targetX = 0;
  let targetY = 0;
  let pointX = 0;
  let pointY = 0;
  const windowHalfX = window.innerWidth / 2;
  const windowHalfY = window.innerHeight / 2;
  let mesh;

  let paintLeft;
  let paintMidl;
  let paintRight;
  let cat;
  let cat_model;
  let dog_model;
  let stone_model;
  let startScene = false;

  let sphere, sphereCat, length1;
  const color = new THREE.Color();
  const vertex = new THREE.Vector3();
  const radius = 0.8,
    segments = 32,
    rings = 26;
  const colors = [];
  const sizes = [];
  let sphereGeometry = new THREE.SphereGeometry(radius, segments, rings);
  sphereGeometry.deleteAttribute("normal");
  sphereGeometry.deleteAttribute("uv");

  // sphereGeometry = BufferGeometryUtils.mergeVertices( sphereGeometry );
  length1 = sphereGeometry.getAttribute("position").count;
  const posAtr = sphereGeometry.getAttribute("position");
  for (let i = 0, l = length1; i < l; i++) {
    vertex.fromBufferAttribute(posAtr, i);

    if (i < length1) {
      color.setHSL(
        0.1 + 0.1 * (i / length1),
        0.99,
        (vertex.y + radius) / (4 * radius)
      );
    } else {
      color.setHSL(1, 1, 1 + vertex.y / (2 * radius));
    }

    color.toArray(colors, i * 3);

    sizes[i] = i < length1 ? 0.2 : 1;
  }
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", posAtr);
  geometry.setAttribute("size", new THREE.Float32BufferAttribute(sizes, 1));
  geometry.setAttribute("ca", new THREE.Float32BufferAttribute(colors, 3));
  const texture = new THREE.TextureLoader().load("static/models/disc.png");
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;

  const material = new THREE.ShaderMaterial({
    uniforms: {
      color: { value: new THREE.Color(0xffffff) },
      pointTexture: { value: texture },
    },
    vertexShader: document.getElementById("vertexshader").textContent,
    fragmentShader: document.getElementById("fragmentshader").textContent,
    transparent: true,
  });

  sphere = new THREE.Points(geometry, material);

  // Canvas
  const canvas = document.querySelector("canvas.webgl");

  // Scene
  const scene = new THREE.Scene();

  /**
   * Camera
   */
  // Base camera
  const camera = new THREE.PerspectiveCamera(
    35,
    window.innerWidth / window.innerHeight,
    0.1,
    10000
  );
  camera.position.x = 5;
  camera.position.y = 0.2;
  camera.position.z = 5;
  camera.lookAt(0, 0, 0);
  scene.add(camera);

  sphere.position.set(-2.5, 0.1, 2.3);
  sphere.scale.set(0.5, 0.5, 0.5);

  const sphereMidl = sphere.clone();
  sphereMidl.position.set(-2.8, 0.1, 0.2);
  sphereMidl.scale.set(1, 1, 1);

  const sphereRight = sphere.clone();
  sphereRight.position.set(4.1, 0.1, 2.2);
  sphereRight.scale.set(1, 1, 1);

  sphereCat = sphere.clone();
  sphereCat.position.set(3.2, 0.1, 3.2);
  sphereCat.scale.set(1, 1, 1);
  // Controls
  // const controls = new OrbitControls(camera, canvas)
  // controls.enableDamping = true

  /**
   * Renderer
   */
  const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true,
  });
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 0;
  renderer.outputColorSpace = THREE.SRGBColorSpace;
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  // Lights

  const hemisphereLight = new THREE.HemisphereLight(0xffffbb, 0x080820, 1);
  scene.add(hemisphereLight);

  const environment = new RoomEnvironment();
  const pmremGenerator = new THREE.PMREMGenerator(renderer);
  pmremGenerator.compileEquirectangularShader();
  scene.environment = pmremGenerator.fromScene(environment).texture;

  /*
   *
   * orbit
   * */

  //controls.update() must be called after any manual changes to the camera's transform
  // camera.position.set( 0, 20, 100 );
  // controls.update();
  // new RGBELoader()
  //     .setDataType( THREE.UnsignedByteType )
  //     .load( '../models/christmas_photo_studio_01_1k.hdr', function ( texture ) {
  //
  //         var envMap = pmremGenerator.fromEquirectangular( texture ).texture;
  //         texture.flipY = true
  //         //scene.background = envMap;
  //          scene.environment = envMap;
  //
  //         texture.dispose();
  //         pmremGenerator.dispose();
  //     })

  // 3d obj
  //
  //
  //
  manager.onProgress = function (item, loaded, total) {
    progress_bar.style.width = (loaded / total) * 100 + "%";
    if (progress_bar.style.width === "100%") {
      gsap.to(preloader, { autoAlpha: 0, duration: 3, delay: 1 });
      gsap.to(camera.position, {
        x: 7,
        y: 0.2,
        z: 7,
        duration: 4,
        delay: 1,
        ease: "power4.inOut",
      });
      gsap.to(renderer, {
        toneMappingExposure: 1,
        duration: 4,
        delay: 1.2,
        ease: "power4.inOut",
      });
      gsap.to(".logo", {
        width: () => (window.innerWidth > 1024 ? "2.08vw" : "29px"),
        x: 0,
        y: 0,
        transformOrigin: "0% 0%",
        top: () =>
          window.innerWidth > 1024
            ? "2.08vw"
            : window.innerWidth >= 567 &&
              window.innerWidth < 1024 &&
              window.innerWidth / window.innerHeight < 1
            ? "58px"
            : window.innerWidth < 567 &&
              window.innerWidth / window.innerHeight < 1
            ? "30px"
            : "30px",
        left: () =>
          window.innerWidth > 1024
            ? "6.944vw"
            : window.innerWidth >= 567 &&
              window.innerWidth < 1024 &&
              window.innerWidth / window.innerHeight < 1
            ? "50px"
            : window.innerWidth < 567 &&
              window.innerWidth / window.innerHeight < 1
            ? "30px"
            : "30px",
        yPercent: 0,
        xPercent: 0,
        delay: 1.4,
        duration: 2,
        ease: "power4.inOut",
        onComplete: () => {
          startScene = true;
          document.querySelector("html").classList.remove("loader");
        },
      });
      gsap.to(".logo .l_name", {
        x: 0,
        y: 0,
        transformOrigin: "0% 0%",
        top: () =>
          window.innerWidth > 1024
            ? "0px"
            : window.innerWidth >= 567 &&
              window.innerWidth < 1024 &&
              window.innerWidth / window.innerHeight < 1
            ? "5px"
            : window.innerWidth < 567 &&
              window.innerWidth / window.innerHeight < 1
            ? "0px"
            : "0px",
        left: () => (window.innerWidth > 1024 ? "2.5vw" : "35px"),
        yPercent: 0,
        xPercent: 0,
        delay: 1.4,
        duration: 2,
        ease: "power4.inOut",
      });
      start_tl.play();
      scrollAnimation();
    }
  };

  const ktx2Loader = new KTX2Loader(manager)
    .setTranscoderPath('three/examples/jsm/libs/basis/')
    .detectSupport(renderer);
  const loader = new GLTFLoader(manager)
    .setCrossOrigin("anonymous")
    .setKTX2Loader(ktx2Loader)
    .setMeshoptDecoder(MeshoptDecoder);

  loader.load(
    "static/models/Gallery_FinalV2_meshopt_ktx2.glb",
    function (gltf) {
      mesh = gltf.scene;
      gltf.scene.traverse(function (child) {
        if (child === child.getObjectByName("mesh_0_5")) {
          paintLeft = child;
          gltf.scene.add(sphere);
        }

        if (child === child.getObjectByName("mesh_0_4")) {
          paintMidl = child;
          gltf.scene.add(sphereMidl);
        }
        if (child === child.getObjectByName("mesh_0_3")) {
          paintRight = child;
          gltf.scene.add(sphereRight);
        }
        if (child === child.getObjectByName("mesh_0_2")) {
          child.material = new THREE.MeshBasicMaterial();
          child.material.color = new THREE.Color(0x000000);
        }
        if (child === child.getObjectByName("mesh_0")) {
          cat = child;
          gltf.scene.add(sphereCat);
        }
      });

      scene.add(gltf.scene);
    },
    (xhr) => {},
    (error) => {
      console.log(error);
    }
  );

  loader.load(
    "static/models/Cat_meshopt_ktx2.glb",
    function (gltf) {
      cat_model = gltf.scene;
      gltf.scene.traverse(function (child) {});
      cat_model.position.set(0, 100, 0);
      cat_model.visible = false;
      scene.add(gltf.scene);
    },
    (xhr) => {},
    (error) => {
      console.log(error);
    }
  );

  loader.load(
    "static/models/Dog_meshopt_kt2.glb",
    function (gltf) {
      dog_model = gltf.scene;
      gltf.scene.traverse(function (child) {});
      dog_model.position.set(0, 100, 0);
      dog_model.visible = false;
      scene.add(gltf.scene);
    },
    (xhr) => {},
    (error) => {
      console.log(error);
    }
  );
  loader.load(
    "static/models/Stone_meshopt_kt2.glb",
    function (gltf) {
      stone_model = gltf.scene;
      gltf.scene.traverse(function (child) {});
      stone_model.position.set(0, 100, 0);
      stone_model.visible = false;
      scene.add(gltf.scene);
    },
    (xhr) => {},
    (error) => {
      console.log(error);
    }
  );

  document.addEventListener("mousemove", onDocumentMouseMove);

  function onDocumentMouseMove(event) {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    pointX = event.clientX - windowHalfX;
    pointY = event.clientY - windowHalfY;
  }

  /**
   * Animate
   */

  // function scrollAnimation(){
  // ScrollTrigger.matchMedia({"(prefers-reduced-motion:no-preference)":desktopAnim()})
  // }

  function scrollAnimation() {
    let tl_slider = gsap.timeline({
      scrollTrigger: {
        trigger: ".slider",
        start: "top-=100%  top",
        end: "+=1000%",
        scrub: 1,
        // onUpdate: ()=>{
        //     camera.lookAt(cat_model.position)
        // }
      },
    });
    tl_slider.to(".webgl", { opacity: 0, duration: 1 });
    tl_slider.to(cat_model, { visible: true, duration: 0.001 });
    tl_slider.to(
      camera.position,
      { y: () => (window.innerWidth > 567 ? 100.2 : 99.5), duration: 0.1 },
      "<"
    );
    tl_slider.to(".webgl", { opacity: 1, duration: 1 });
    tl_slider.to(
      camera,
      {
        zoom: () => (window.innerWidth > 1024 ? 2.5 : 1.5),
        duration: 1,
        onUpdate: () => {
          camera.updateProjectionMatrix();
        },
      },
      "<"
    );
    tl_slider.to(cat_model.rotation, { y: () => 2 * Math.PI, duration: 3 });
    tl_slider.to(camera, {
      zoom: 2,
      duration: 1,
      onUpdate: () => {
        camera.updateProjectionMatrix();
      },
    });
    tl_slider.to(".webgl", { opacity: 0, duration: 1 }, "<");
    tl_slider.to(cat_model, { visible: false, duration: 0.001 });
    tl_slider.to(dog_model, { visible: true, duration: 0.001 });
    tl_slider.to(".webgl", { opacity: 1, duration: 1 });
    tl_slider.to(
      camera,
      {
        zoom: () => (window.innerWidth > 1024 ? 2.5 : 1.5),
        duration: 1,
        onUpdate: () => {
          camera.updateProjectionMatrix();
        },
      },
      "<"
    );
    tl_slider.to(
      ".header_num .num1",
      { yPercent: "-=100", duration: 1, ease: "power4.inOut" },
      "<"
    );
    tl_slider.to(
      ".header_num .num2",
      { yPercent: "-=100", duration: 1, ease: "power4.inOut" },
      "<"
    );
    tl_slider.to(
      ".slider_text1",
      { opacity: 0, yPercent: "-=20", duration: 2, ease: "power4.inOut" },
      "<"
    );
    tl_slider.to(
      ".slider_text2",
      { opacity: 1, y: "40", duration: 2, ease: "power4.inOut" },
      "<"
    );
    tl_slider.to(dog_model.rotation, { y: () => 2 * Math.PI, duration: 3 });
    tl_slider.to(camera, {
      zoom: 2,
      duration: 1,
      onUpdate: () => {
        camera.updateProjectionMatrix();
      },
    });
    tl_slider.to(".webgl", { opacity: 0, duration: 1 }, "<");
    
    // Add Stone model transition
    tl_slider.to(dog_model, { visible: false, duration: 0.001 });
    tl_slider.to(stone_model, { visible: true, duration: 0.001 });
    tl_slider.to(".webgl", { opacity: 1, duration: 1 });
    tl_slider.to(
      camera,
      {
        zoom: () => (window.innerWidth > 1024 ? 2.5 : 1.5),
        duration: 1,
        onUpdate: () => {
          camera.updateProjectionMatrix();
        },
      },
      "<"
    );
    tl_slider.to(
      ".header_num .num2",
      { yPercent: "-=100", duration: 1, ease: "power4.inOut" },
      "<"
    );
    tl_slider.to(
      ".header_num .num3",
      { yPercent: "-=100", duration: 1, ease: "power4.inOut" },
      "<"
    );
    tl_slider.to(
      ".slider_text2",
      { opacity: 0, yPercent: "-=20", duration: 2, ease: "power4.inOut" },
      "<"
    );
    tl_slider.to(
      ".slider_text3",
      { opacity: 1, y: "40", duration: 2, ease: "power4.inOut" },
      "<"
    );
    tl_slider.to(stone_model.rotation, { y: () => 2 * Math.PI, duration: 3 });

    tl_slider.to(
      ".status_prog",
      { height: "100%", duration: 15, ease: "none" },
      1
    );
  }
  //***//

  const clock = new THREE.Clock();

  const tick = () => {
    const elapsedTime = clock.getElapsedTime();

    targetX = pointX * 0.0001;
    targetY = pointY * 0.0001;

    if (mesh) {
      mesh.rotation.y += 0.005 * (targetX - mesh.rotation.y);
      //mesh.rotation.x += 0.0025 * ( targetY - mesh.rotation.x );
    }
    if (cat_model) {
      cat_model.position.x = -2 * targetX;
      cat_model.position.z = -2 * targetY;
    }
    if (startScene) {
      raycaster.setFromCamera(mouse, camera);
    }
    if (paintLeft) {
      const intersects = raycaster.intersectObject(paintLeft, true);
      if (intersects.length > 0) {
        gsap.to(paintLeft.position, { x: 150, duration: 2 });
        gsap.to(sphere.scale, { x: 0.8, y: 1.2, z: 0.8, duration: 1 });
        gsap.to(sphere.position, { x: -2.8, y: 0.1, z: 2.1, duration: 1 });
      } else {
        gsap.to(paintLeft.position, { x: 0, duration: 2 });
        gsap.to(sphere.scale, { x: 0.5, y: 0.5, z: 0.5, duration: 1 });
        gsap.to(sphere.position, { x: -4, y: 0.1, z: 2, duration: 1 });
      }
    }
    if (paintMidl) {
      const intersects = raycaster.intersectObject(paintMidl, true);
      if (intersects.length > 0) {
        gsap.to(paintMidl.position, { x: 150, duration: 2 });
        gsap.to(sphereMidl.scale, { x: 0.8, y: 1.2, z: 0.8, duration: 1 });
        gsap.to(sphereMidl.position, { x: -2.8, y: 0.1, z: 0.2, duration: 1 });
      } else {
        gsap.to(paintMidl.position, { x: 0, duration: 2 });
        gsap.to(sphereMidl.scale, { x: 0.5, y: 0.5, z: 0.5, duration: 1 });
        gsap.to(sphereMidl.position, { x: -4, y: 0.1, z: 0.2, duration: 1 });
      }
    }
    if (paintRight) {
      const intersects = raycaster.intersectObject(paintRight, true);
      if (intersects.length > 0) {
        gsap.to(paintRight.position, { z: 150, duration: 2 });
        gsap.to(sphereRight.scale, { x: 0.8, y: 1.1, z: 0.8, duration: 1 });
        gsap.to(sphereRight.position, { x: 4.1, y: 0.1, z: 2.2, duration: 1 });
      } else {
        gsap.to(paintRight.position, { z: 0, duration: 2 });
        gsap.to(sphereRight.scale, { x: 0.5, y: 0.5, z: 0.5, duration: 1 });
        gsap.to(sphereRight.position, { x: 3.1, y: 0.1, z: 0.2, duration: 1 });
      }
    }

    if (cat) {
      const intersects = raycaster.intersectObject(cat, true);
      if (intersects.length > 0) {
        gsap.to(cat.position, { y: 20, duration: 2 });
        gsap.to(sphereCat.scale, { x: 0.7, y: 1, z: 0.7, duration: 1 });
        gsap.to(sphereCat.position, { x: 3.2, y: 0.1, z: 3.2, duration: 1 });
      } else {
        gsap.to(cat.position, { y: 0, duration: 2 });
        gsap.to(sphereCat.scale, { x: 0, y: 0, z: 0, duration: 1 });
        gsap.to(sphereCat.position, { x: 2.1, y: 0.1, z: 2.1, duration: 1 });
      }
    }

    // sphere.rotation.y = 0.2 * elapsedTime;
    // sphere.rotation.z = 0.2 * elapsedTime;
    // sphereMidl.rotation.y = 0.2 * elapsedTime;
    // sphereMidl.rotation.z = 0.2 * elapsedTime;
    // sphereRight.rotation.y = 0.2 * elapsedTime;
    // sphereRight.rotation.z = 0.2 * elapsedTime;
    // sphereCat.rotation.y = 0.2 * elapsedTime;
    // sphereCat.rotation.z = 0.2 * elapsedTime;

    // Render
    renderer.render(scene, camera);
    window.requestAnimationFrame(tick);
  };

  tick();

  window.addEventListener("resize", () => {
    // Update camera
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    // Update renderer
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  });
}

function threeJs_logo() {
  //price
  let element = "item1";
  const price_sect = document.querySelectorAll(".price_wrap .item");
  price_sect.forEach((el) => {
    el.addEventListener("click", () => {
      price_sect.forEach((el) => {
        if (el.classList.contains("item1")) {
          gsap.to(el, {
            background:
              "linear-gradient(135.38deg, #cba147 -19.82%, #8258b6 100%)",
          });
        }
        if (el.classList.contains("item2")) {
          gsap.to(el, {
            background:
              "linear-gradient(135.38deg, #cba147 -19.82%, #8258b6 100%)",
          });
        }
        if (el.classList.contains("item3")) {
          gsap.to(el, {
            background:
              "linear-gradient(135.38deg, #bc925f -19.82%, #744bc8 100%)",
          });
        }
        if (el.classList.contains("item4")) {
          gsap.to(el, {
            background:
              "linear-gradient(135.38deg, #af8570 -19.82%, #673edb 100%)",
          });
        }
        gsap.to(el, { width: "25%" });
        if (
          window.innerWidth < 1024 &&
          window.innerWidth / window.innerHeight < 1
        ) {
          gsap.to(el, { height: "100%" });
          gsap.to(el.querySelector(".name_plan"), { top: "50%" });
        }
        gsap.to(el.querySelector(".radials"), { opacity: 0 });
        gsap.to(el.querySelector(".name_plan span"), {
          x: () => (window.innerWidth > 1024 ? -100 : 0),
        });
        gsap.to(el.querySelector(".name_plan span"), { opacity: 1 });
        gsap.to(el.querySelector(".description"), { opacity: 0 });
        // el.classList.remove('active')
      });
      if (
        window.innerWidth < 1024 &&
        window.innerWidth / window.innerHeight < 1
      ) {
        gsap.to(el.querySelector(".name_plan"), { top: "30%" });
        gsap.to(el, { height: "250%" });
        gsap.to(el.querySelector(".description"), { opacity: 1 });
      } else {
        gsap.to(el, { width: "40%" });
        gsap.to(el.querySelector(".description"), { opacity: 1 });
      }
      gsap.to(el, {
        background: "linear-gradient(135deg, #130930 0%, #643BE0 100%)",
      });

      gsap.to(el.querySelector(".radials"), { opacity: 1 });
      gsap.to(el.querySelector(".name_plan span"), { x: 100 });
      gsap.to(el.querySelector(".name_plan span"), { opacity: 0 });

      if (el.classList.contains("item1")) {
        element = "item1";
      }
      if (el.classList.contains("item2")) {
        element = "item2";
      }
      if (el.classList.contains("item3")) {
        element = "item3";
      }
      if (el.classList.contains("item4")) {
        element = "item4";
      }
    });
  });

  const mouse = new THREE.Vector2();

  let targetX = 0;
  let targetY = 0;
  let pointX = 0;
  let pointY = 0;
  const windowHalfX = window.innerWidth / 2;
  const windowHalfY = window.innerHeight / 2;
  let mesh;

  // Scene
  const scene = new THREE.Scene();

  /**
   * Camera
   */
  // Base camera
  const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.1,
    10000
  );
  camera.position.x = 0;
  camera.position.y = 0;
  camera.position.z = 5;
  camera.lookAt(0, 0, 0);
  scene.add(camera);

  /**
   * Renderer
   */
  const renderer = new THREE.WebGLRenderer({
    canvas: document.getElementById("logo_canvas"),
    alpha: true,
    antialias: true,
  });
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1;
  renderer.outputColorSpace = THREE.SRGBColorSpace;
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  // Lights

  const light = new THREE.DirectionalLight(0xffffff, 1.1);
  light.position.set(3, 0.8, 3);
  light.castShadow = true;
  light.target.position.set(-5, -10, -3);
  light.target.updateMatrixWorld();
  scene.add(light);

  light.shadow.mapSize.width = 2048;
  light.shadow.mapSize.height = 2048;
  light.shadow.camera.near = 0.5;
  light.shadow.camera.far = 500;

  const hemisphereLight1 = new THREE.HemisphereLight(0xffffbb, 0x080820, 1.2);
  scene.add(hemisphereLight1);

  const pmremGenerator = new THREE.PMREMGenerator(renderer);
  pmremGenerator.compileEquirectangularShader();

  new RGBELoader()
    .setDataType(THREE.HalfFloatType)
    .load("static/models/christmas_photo_studio_01_1k.hdr", function (texture) {
      var envMap = pmremGenerator.fromEquirectangular(texture).texture;
      texture.flipY = true;
      //scene.background = envMap;
      scene.environment = envMap;

      texture.dispose();
      pmremGenerator.dispose();
    });

  const loader = new GLTFLoader();

  loader.load(
    "static/models/logo.glb",
    function (gltf) {
      mesh = gltf.scene;
      gltf.scene.traverse(function (child) {
        if (child.isMesh) {
          child.receiveShadow = true;
          child.castShadow = true;
          child.scale.set(0.25, 0.25, 0.25);
          child.position.set(0, 1.1, 0);
        }
      });

      scene.add(gltf.scene);
    },
    (xhr) => {},
    (error) => {
      console.log(error);
    }
  );

  document.addEventListener("mousemove", onDocumentMouseMove);

  function onDocumentMouseMove(event) {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    pointX = event.clientX - windowHalfX;
    pointY = event.clientY - windowHalfY;
  }

  //***//

  const clock = new THREE.Clock();

  const tick = () => {
    const elapsedTime = clock.getElapsedTime();

    targetX = pointX * 0.0001;
    targetY = pointY * 0.0001;

    if (mesh) {
      if (window.innerWidth > 1024) {
        mesh.rotation.y += 1 * (targetX - mesh.rotation.y);
        mesh.rotation.x += 1 * (targetY - mesh.rotation.x);
        if (element === "item1") {
          gsap.to(mesh.position, {
            x: -2.7,
            delay: 0.5,
            duration: 1,
            ease: "power4.Out",
          });
        }
        if (element === "item2") {
          gsap.to(mesh.position, {
            x: -1.1,
            delay: 0.5,
            duration: 1,
            ease: "power4.Out",
          });
        }
        if (element === "item3") {
          gsap.to(mesh.position, {
            x: 0.3,
            delay: 0.5,
            duration: 1,
            ease: "power4.Out",
          });
        }
        if (element === "item4") {
          gsap.to(mesh.position, {
            x: 2,
            delay: 0.5,
            duration: 1,
            ease: "power4.Out",
          });
        }
      }
      if (
        window.innerWidth <= 1024 &&
        window.innerWidth / window.innerHeight > 1
      ) {
        if (element === "item1") {
          gsap.to(mesh.position, {
            x: -2,
            delay: 0.5,
            duration: 1,
            ease: "power4.Out",
          });
        }
        if (element === "item2") {
          gsap.to(mesh.position, {
            x: -0.8,
            delay: 0.5,
            duration: 1,
            ease: "power4.Out",
          });
        }
        if (element === "item3") {
          gsap.to(mesh.position, {
            x: 0.3,
            delay: 0.5,
            duration: 1,
            ease: "power4.Out",
          });
        }
        if (element === "item4") {
          gsap.to(mesh.position, {
            x: 1.5,
            delay: 0.5,
            duration: 1,
            ease: "power4.Out",
          });
        }
      }
      if (
        window.innerWidth < 1024 &&
        window.innerWidth / window.innerHeight < 1
      ) {
        if (element === "item1") {
          gsap.to(mesh.position, {
            y: 0.1,
            delay: 0.5,
            duration: 1,
            ease: "power4.Out",
          });
        }
        if (element === "item2") {
          gsap.to(mesh.position, {
            y: -0.5,
            delay: 0.5,
            duration: 1,
            ease: "power4.Out",
          });
        }
        if (element === "item3") {
          gsap.to(mesh.position, {
            y: -1.1,
            delay: 0.5,
            duration: 1,
            ease: "power4.Out",
          });
        }
        if (element === "item4") {
          gsap.to(mesh.position, {
            y: -1.7,
            delay: 0.5,
            duration: 1,
            ease: "power4.Out",
          });
        }
      }
    }

    // Render
    renderer.render(scene, camera);
    window.requestAnimationFrame(tick);
  };

  tick();

  window.addEventListener("resize", () => {
    // Update camera
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    // Update renderer
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  });
}
